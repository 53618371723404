import * as React from "react";
import { Address } from "@/types/index";
import { LeadContextInterface } from "@/types/broadbandLeadContext";

export const LeadContext = React.createContext<LeadContextInterface>({
	postcode: "",
	setPostcode: (postcode: string) => {},
	address: null,
	setAddress: (address: Address) => {},
	timeAtAddress: "",
	setTimeAtAddress: (timeAtAddress: string) => {},
	contractStatus: "",
	setContractStatus: (contractStatus: string) => {},
	currentProvider: "",
	setCurrentProvider: (currentProvider: string) => {},
	preferredSpeed: "",
	setPreferredSpeed: (preferredSpeed: string) => {},
	firstName: "",
	setFirstName: (firstName: string) => {},
	lastName: "",
	setLastName: (lastName: string) => {},
	contactNumber: "",
	setContactNumber: (contactNumber: string) => {},
	variant: 0,
	setVariant: (variant: number) => {},
	email: "",
	setEmail: (email: string) => {},

	clearState: () => {},
});

const LeadProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const [postcode, setPostcode] = React.useState<string>("");
	const [address, setAddress] = React.useState<Address | null>(null);
	const [timeAtAddress, setTimeAtAddress] = React.useState<string>("");
	const [contractStatus, setContractStatus] = React.useState<string>("");
	const [currentProvider, setCurrentProvider] = React.useState<string>("");
	const [preferredSpeed, setPreferredSpeed] = React.useState<string>("");
	const [firstName, setFirstName] = React.useState<string>("");
	const [lastName, setLastName] = React.useState<string>("");
	const [email, setEmail] = React.useState<string>("");
	const [contactNumber, setContactNumber] = React.useState<string>("");
	const [variant, setVariant] = React.useState<number>(0);

	const clearState = () => {
		setPostcode("");
		setAddress(null);
		setCurrentProvider("");
		setContractStatus("");
		setPreferredSpeed("");
		setTimeAtAddress("");
	};

	return (
		<LeadContext.Provider
			value={{
				timeAtAddress,
				setTimeAtAddress,
				contractStatus,
				setContractStatus,
				postcode,
				setPostcode,
				address,
				setAddress,
				currentProvider,
				setCurrentProvider,
				preferredSpeed,
				setPreferredSpeed,
				firstName,
				setFirstName,
				lastName,
				setLastName,
				email,
				setEmail,
				contactNumber,
				setContactNumber,
				variant,
				setVariant,
				clearState,
			}}
		>
			{children}
		</LeadContext.Provider>
	);
};

export default LeadProvider;
