import * as React from "react";
import Cookies from "js-cookie";
import { useRouter } from "next/router";

export type UtmContextInterface = {
	utmSource: string | undefined;
	setUtmSource: (utmSource: string) => void;
	utmMedium: string | undefined;
	setUtmMedium: (utmMedium: string) => void;
	utmCampaign: string | undefined;
	setUtmCampaign: (utmCampaign: string) => void;
	utmTerm: string | undefined;
	setUtmTerm: (utmTermd: string) => void;
	utmContent: string | undefined;
	setUtmContent: (utmContent: string) => void;
};

export const UtmContext = React.createContext<UtmContextInterface>({
	utmSource: undefined,
	setUtmSource: (utmSource: string | undefined) => {},
	utmMedium: undefined,
	setUtmMedium: (utmMedium: string | undefined) => {},
	utmCampaign: undefined,
	setUtmCampaign: (utmCampaign: string | undefined) => {},
	utmTerm: undefined,
	setUtmTerm: (utmTerm: string | undefined) => {},
	utmContent: undefined,
	setUtmContent: (utmContent: string | undefined) => {},
});

const UtmProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const router = useRouter();
	const [utmSource, setUtmSource] = React.useState<string | undefined>(
		undefined
	);
	const [utmMedium, setUtmMedium] = React.useState<string | undefined>(
		undefined
	);
	const [utmCampaign, setUtmCampaign] = React.useState<string | undefined>(
		undefined
	);
	const [utmTerm, setUtmTerm] = React.useState<string | undefined>(undefined);
	const [utmContent, setUtmContent] = React.useState<string | undefined>(
		undefined
	);

	const cookieGetSet = (
		key: string,
		value: string | undefined,
		setter: (value: string) => void
	) => {
		if (Cookies.get(key) !== value && value !== undefined) {
			Cookies.set(key, value as string);
			setter(value);
		}
	};

	React.useEffect(() => {
		if (!router.isReady) {
			return;
		}

		const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } =
			router.query;

		setUtmSource(Cookies.get("utmSource"));
		setUtmMedium(Cookies.get("utmMedium"));
		setUtmCampaign(Cookies.get("utmCampaign"));
		setUtmTerm(Cookies.get("utmTerm"));
		setUtmContent(Cookies.get("utmContent"));

		cookieGetSet("utmSource", utm_source as string | undefined, setUtmSource);
		cookieGetSet("utmMedium", utm_medium as string | undefined, setUtmMedium);
		cookieGetSet(
			"utmCampaign",
			utm_campaign as string | undefined,
			setUtmCampaign
		);
		cookieGetSet("utmTerm", utm_term as string | undefined, setUtmTerm);
		cookieGetSet(
			"utmContent",
			utm_content as string | undefined,
			setUtmContent
		);
	}, [
		router,
		setUtmSource,
		setUtmMedium,
		setUtmCampaign,
		setUtmTerm,
		setUtmContent,
	]);

	return (
		<UtmContext.Provider
			value={{
				utmSource,
				setUtmSource,
				utmMedium,
				setUtmMedium,
				utmCampaign,
				setUtmCampaign,
				utmTerm,
				setUtmTerm,
				utmContent,
				setUtmContent,
			}}
		>
			{children}
		</UtmContext.Provider>
	);
};

export default UtmProvider;
